import React, { useRef, useCallback, useState, useEffect } from "react"
import { navigate } from "gatsby"
import { useLocation } from "@gatsbyjs/reach-router"
import DadataOrganization from "@ecom/ui/components/FormLanding/fields/DadataFields/DadataOrganization"

import PhoneField from "@ecom/ui/components/FormLanding/fields/PhoneField"
import SelectField from "@ecom/ui/components/FormLanding/fields/SelectField"
import DadataAddress from "@ecom/ui/components/FormLanding/fields/DadataFields/DadataAddress"
import DateField from "@ecom/ui/components/FormLanding/fields/DateField"
import MaskedField from "@ecom/ui/components/FormLanding/fields/MaskedField"
import requiredValidator from "@ecom/ui/components/FormLanding/validators/required"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"

import clsx from "clsx"
import { Box } from "@material-ui/core"
import FormControl from "../FormControl"

import {
  SOCIAL_STATUS_LIST,
  WORK_SECTORS,
  formatValuesToRequest,
  workPhoneValidator,
  passportDateValidator,
} from "./helpers"
import disableWebvisor from "../../utils/disableWebvisor"
import { getIDBValue, setIDBValue } from "../../utils/idbUtils"
import { getAddressForDelivery } from "../../helpers/getAddress"
import { noValidate } from "../../helpers/validators"

import type { FieldData } from "../../interfaces/fieldData"
import type { EmploymentFormData } from "./types"
import * as styles from "./employmentRedesign.module.scss"
import { LongAppWrapper } from "../LongAppWrapper"
import { GuardSecuritySvg } from "../../images/svg/Icon"
import { SecondStepIcon } from "../../images/svg/SecondStep"
import { sendRequest } from "../../helpers/sendRequest"
import { events } from "../../helpers/events"
import { HalvaResponse } from "../../interfaces/responseStatus"

const DADATA_OPTIONS = { to_bound: { value: "house" } }

function getFieldsWithRequredStatus(requiredValue: boolean) {
  const workFields = [
    "workName",
    "workSector",
    "workDate",
    "workAddress",
    "workPersonalPhone",
    "workAdditionalPersonalPhone",
  ]
  const fieldsWithRequredStatus = workFields.map((field) => [field, { isRequired: requiredValue }])

  return Object.fromEntries(fieldsWithRequredStatus)
}

export default function Employment() {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isRequired, setIsRequired] = useState(true)
  const [requestId, setRequestId] = useState("")
  const refForm = useRef<any>(null)
  const location = useLocation()

  const onChangeFields = {
    socialStatus: ({ value }: FieldData<number>) => {
      const isRequiredFields = [5, 3].includes(value)
      setIsRequired(!isRequiredFields)
      if (isRequiredFields) {
        refForm.current.setFields(getFieldsWithRequredStatus(false))
      } else {
        refForm.current.setFields(getFieldsWithRequredStatus(true))
      }
    },
  }
  // отключение записи ПД в веббвизореe
  const ref = useCallback((node: any) => {
    disableWebvisor(node)
  }, [])

  useEffect(() => {
    getIDBValue<string>("requestId").then((value) => {
      if (value) {
        setRequestId(value)
      }
    })
  }, [])

  function handleSubmit(values: EmploymentFormData) {
    setIsSubmitting(true)
    const valuesToRequest = formatValuesToRequest(values)
    const { workAddress } = values

    if (workAddress) {
      const workAddressData = getAddressForDelivery(
        workAddress,
        valuesToRequest.workAddress as string
      )
      setIDBValue("workAddress", workAddressData)
    }

    sendRequest(`/v2/app/public/halva/${requestId}`, { ...valuesToRequest }, true)
      .then(({ status, apiId }: HalvaResponse) => {
        switch (status) {
          case "reject":
            navigate("/error-reject")
            pushToDataLayer(events.applicationSend.reject)
            return
          case "error":
            throw Error(status)
          default:
            break
        }

        setIDBValue("requestId", apiId)
      })
      .then(() => {
        navigate("/deliveryRedesign/", { state: { thanksPage: location.state.thanksPage } })
      })
      .catch(() => {
        pushToDataLayer(events.applicationSend.error)
        pushToDataLayer(events.GAFormEvent.error)
        navigate("/error-technical/")
      })
  }

  return (
    <LongAppWrapper
      name="employmentForm"
      handleSubmit={handleSubmit}
      onChangeFields={onChangeFields}
      refForm={refForm}
      isDisabled={isSubmitting}
      stepIcon={<SecondStepIcon />}
      withSteps
      variant="custom"
      title={
        <h2 className={clsx(styles.formHead, styles.main)} ref={ref}>
          Занятость
        </h2>
      }
    >
      <div className={styles.longContainerFluid}>
        <div className={styles.wrapGuaranteeSecurity}>
          <GuardSecuritySvg color="#76BC21" />
          <p>Мы гарантируем безопасность и сохранность ваших данных</p>
        </div>
        <h2 className={clsx(styles.indent, styles.formHead)}>Заполните данные </h2>
        <FormControl>
          <SelectField
            label="Социальный статус"
            name="socialStatus"
            defaultValue=""
            validate={requiredValidator}
            items={SOCIAL_STATUS_LIST}
          />
        </FormControl>
        {isRequired && (
          <>
            <FormControl>
              <DadataOrganization
                label="Название организации"
                name="workName"
                defaultValue=""
                validate={requiredValidator}
              />
            </FormControl>
            <Box className={styles.fieldWrapper}>
              <FormControl className={styles.shortField}>
                <SelectField
                  label="Отрасль"
                  name="workSector"
                  defaultValue=""
                  validate={requiredValidator}
                  items={WORK_SECTORS}
                />
              </FormControl>
              <FormControl className={styles.shortField}>
                {location.state?.birthDate ? (
                  <DateField
                    InputProps={{
                      inputProps: {
                        inputMode: "numeric",
                      },
                    }}
                    label="Дата начала работы"
                    name="workDate"
                    max={new Date()}
                    validate={(val: string) => passportDateValidator(val, location.state.birthDate)}
                    defaultValue=""
                  />
                ) : (
                  <DateField
                    InputProps={{
                      inputProps: {
                        inputMode: "numeric",
                      },
                    }}
                    label="Дата начала работы"
                    name="workDate"
                    max={new Date()}
                    defaultValue=""
                  />
                )}
              </FormControl>
            </Box>
            <h2 className={styles.formHead}> Контактные данные организации</h2>
            <FormControl>
              <DadataAddress
                label="Адрес места работы"
                name="workAddress"
                dadataOptions={DADATA_OPTIONS}
                helperText="Регион, город, улица, дом"
              />
            </FormControl>
            <FormControl className={styles.shortField}>
              <PhoneField
                name="workPersonalPhone"
                defaultValue=""
                label="Рабочий телефон"
                placeholder="+7 (000) 000-00-00"
                validate={workPhoneValidator}
              />
            </FormControl>
          </>
        )}
        <h2 className={styles.formHead}>Доход</h2>
        <Box className={styles.fieldWrapper}>
          <FormControl className={styles.shortField}>
            <MaskedField
              InputProps={{
                inputProps: {
                  inputMode: "numeric",
                },
              }}
              label="Основной доход"
              name="salary"
              mask={Number}
              max={10000000}
              thousandsSeparator=" "
              helperText="Сумма зарплаты, пенсии и т.д"
              validate={requiredValidator}
            />
          </FormControl>
          <FormControl className={styles.shortField}>
            <MaskedField
              InputProps={{
                inputProps: {
                  inputMode: "numeric",
                },
              }}
              label="Дополнительные доходы"
              name="otherSalary"
              mask={Number}
              max={10000000}
              thousandsSeparator=" "
              helperText="Сумма дополнительного дохода"
              validate={noValidate}
            />
          </FormControl>
        </Box>
      </div>
    </LongAppWrapper>
  )
}
